export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Listings',
    route: 'listing',
    icon: 'BookIcon',
    access: 'agent,team,brokerage',
    children: [
      {
        title: 'Residential',
        route: 'residential',
      },
      {
        title: 'Commercial',
        route: 'commercial',
      },
      {
        title: 'Rental',
        route: 'rental',
      },
      {
        title: 'Predefines',
        route: 'predefines',
      },
    ],
  },
  {
    title: 'Medias',
    route: 'Medias',
    icon: 'YoutubeIcon',
  },
  {
    title: 'Team',
    route: 'teams',
    role: 'admin',
    icon: 'UserIcon',
    access: 'team',
  },
  {
    title: 'Agents',
    route: 'brokerage',
    role: 'admin',
    icon: 'UserIcon',
    access: 'brokerage',
  },
  {
    title: 'Users',
    route: 'users',
    role: 'admin',
    icon: 'UserIcon',
    access: 'agent,brokerage',
  },
  {
    title: 'Super Admin',
    icon: 'UserIcon',
    access: 'superuser',
    children: [
      {
        title: 'Accounts',
        route: 'Accounts',
      },
      {
        title: 'Offices',
        route: 'offices',
      },
      {
        title: 'Tickets',
        route: 'tickets',
      },
      {
        title: 'Records',
        route: 'records',
      },
      // {
      //   title: 'Billings',
      //   route: 'Superadmin Billings',
      // },
    ],
  },
  {
    title: 'Leads',
    route: 'leads',
    icon: 'TrendingUpIcon',
    access: 'agent,team,brokerage',
  },
  {
    title: 'Billings',
    route: 'Billings',
    icon: 'FileIcon',
    access: 'agent,team,brokerage',
  },
  {
    title: 'Website',
    // route: 'Website',
    icon: 'MonitorIcon',
    access: 'agent,team,brokerage',
    children: [
      {
        title: 'Style',
        route: 'Style',
      },
      {
        title: 'Setting',
        route: 'Setting',
      },
    ],
  },
]
