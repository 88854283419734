<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getClient.name }}
            </p>
            <span class="user-status">{{ getClient.memberType }}</span>
          </div>
          <b-avatar
            v-if="getThumbnail === null"
            class="badge-minimal"
            badge-variant="success"
            size="40"
            variant="light-primary"
            badge
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="getThumbnail"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name: 'Profile'})"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          v-for="(item, index) in getOtherAccounts"
          :key="index"
          link-class="d-flex align-items-center justify-content-between"
          @click="switchAccount(item)"
        >
          <div>
            <feather-icon
              size="16"
              icon="LogInIcon"
              class="mr-50"
            />
            <span style="text-transform:Capitalize">{{ item.account_name }} ({{ item.account_type }})</span>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$store.commit('SET_IS_ADD_MODAL', true)"
        >
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />
          <span>Add Account</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          v-if="checkItem"
          link-class="d-flex align-items-center"
          @click="$router.push({name: 'Support'})"
        >
          <feather-icon
            size="16"
            icon="AlertCircleIcon"
            class="mr-50"
          />
          <span>Support</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      clientName: '',
      role: '',
      thumbnailUrl: '',
      client: {},
    }
  },
  computed: {
    checkItem() {
      if (this.$store.state.clientInfo.memberType !== 'superuser') {
        return true
      }
      return false
    },
    getOtherAccounts() {
      return this.$store.state.clientInfo.otherAccount
    },
    getClientName() {
      return this.$store.state.clientInfo.name
    },
    getClient() {
      return this.$store.state.clientInfo
    },
    getThumbnail() {
      if (this.$store.state.clientInfo.profile_image) {
        return this.$store.state.clientInfo.profile_image
      }

      return this.$store.state.clientInfo.profile_image
    },
  },
  mounted() {
    this.client = this.$store.state.clientInfo
    this.role = this.client.role
    this.thumbnailUrl = this.client.profile_image ? this.client.profile_image : this.client.detail.profile_image
  },
  methods: {
    logout() {
      this.$store.dispatch('users/logout').then(() => {
        localStorage.removeItem('token')
        this.$store.commit('SET_LOGIN_TIME', '')
        this.$store.commit('setClientInfo', {})
        this.$router.push({ name: 'login' })
      }).catch(err => {
        console.error(err)
      })
    },
    switchAccount(item) {
      this.$store.dispatch('users/switchLogout', item.unique_id).then(response => {
        localStorage.removeItem('token')
        this.$store.commit('SET_LOGIN_TIME', '')
        this.$store.commit('setClientInfo', {})
        this.$store.dispatch('users/switchLogin', response.data).then(switchResponse => {
          localStorage.setItem('token', switchResponse.data.token)
          this.$store.commit('SET_LOGIN_TIME', new Date().getTime())
          this.$store.commit('setClientInfo', switchResponse.data)
          window.location.href = '/'
        }).catch(err => {
          console.error(err)
        })
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style lang="scss">
  .user-status {
    text-transform: capitalize;
  }
  .header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu{
    min-width:12rem;
    width:auto !important;
  }
</style>
